import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../ui/Container"
import {
  Article,
  PostFooter,
  PostHeader,
  PostSubheaderLine,
  PostTitle,
  TagsLine,
} from "../ui/post"
import { Card } from "../ui/Cards"
import { SoftLink } from "../ui/SoftLink"
import { Button } from "../ui/Button"
import { PostCardList } from "../ui/post-list"
import styled from "@emotion/styled"
import tagDescriptions from "../tags.yaml"

const PostCard = props => {
  const { snippet, frontmatter, fields } = props
  const { title, posted, tags } = frontmatter
  const { slug } = fields

  const url = `/blog` + slug

  return (
    <Card>
      <Article>
        <PostHeader>
          <PostTitle as="h2">
            <SoftLink to={url}>{title}</SoftLink>
          </PostTitle>
          <PostSubheaderLine posted={posted} />
        </PostHeader>
        {tags.length > 0 && (
          <PostFooter>
            <TagsLine tags={tags} />
          </PostFooter>
        )}
        <Button to={url}>READ MORE</Button>
      </Article>
    </Card>
  )
}

const TagsHeader = styled(Card)`
  margin-bottom: 32px;

  h1 {
    margin: 0;
  }
`

const TagsPageTemplate = ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges
  const { tag } = pageContext
  const desc = tagDescriptions[tag]

  return (
    <Layout>
      <SEO title={`Topic: ${tag}`} description={desc} />
      <Container>
        <main>
          <TagsHeader>
            <h1>Topic: {tag}</h1>
            {desc && <p>{desc}</p>}
          </TagsHeader>
          <PostCardList>
            {posts.map(edge => (
              <PostCard key={edge.node.id} {...edge.node} />
            ))}
          </PostCardList>
        </main>
      </Container>
    </Layout>
  )
}

export default TagsPageTemplate

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: [$tag] } }
        fields: { sourceName: { eq: "posts" } }
      }
      sort: { fields: [frontmatter___posted], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            posted
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
