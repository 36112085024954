import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"

const SoftAnchor = styled.a`
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dotted currentColor;
`

/**
 * Softly styled link for mailto/tel links
 */
export const SoftLink = props => {
  const { href: hrefProp, to, mailto, tel, children, ...other } = props
  const href = mailto ? `mailto:${mailto}` : tel ? `tel:${tel}` : hrefProp

  if (to) {
    return (
      <SoftAnchor {...other} as={Link} to={to}>
        {children}
      </SoftAnchor>
    )
  }

  return (
    <SoftAnchor {...other} href={href}>
      {children}
    </SoftAnchor>
  )
}
